import React, { Component } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import khaos02 from '../content/khaos02.png';
import khaos03 from '../content/khaos03.png';
import khaos04 from '../content/khaos04.png';
import khaos05 from '../content/khaos05.png';
import khaos01 from '../content/khaos01.png';
import khaos02thumb from '../content/khaos02thumb.png';
import khaos03thumb from '../content/khaos03thumb.png';
import khaos04thumb from '../content/khaos04thumb.png';
import khaos05thumb from '../content/khaos05thumb.png';
import khaos01thumb from '../content/khaos01thumb.png';


class Gallery extends Component {
    componentDidMount () {
        let isMobile = this.props.isMobile;
    }

    render() {
        const images = [
             { original: khaos02, thumbnail: khaos02thumb },
             { original: khaos03, thumbnail: khaos03thumb },
             { original: khaos04, thumbnail: khaos04thumb },
             { original: khaos05, thumbnail: khaos05thumb },
             { original: khaos01, thumbnail: khaos01thumb }
           ]
      

        return (
            <React.Fragment>
                <Header isMobile={this.props.isMobile}/>
                <center>
                    <div style={{width:'75%', textAlign:'center'}}>
                        <div className="ThemeFont FontSizeLevel7" style={{color:'white',textAlign:'left'}}>gallery</div>
                        <hr className="AccentColor" style={{marginTop:'-20px'}} />
                    </div>

                    <div style={{padding:'0px 50px 50px 50px',maxWidth:'800px',textAlign:'center'}}>
                        <ImageGallery items={images} showThumbnails={false} />
                    </div>
                </center>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Footer/>
            </React.Fragment>
        )
    }
}
export default Gallery;