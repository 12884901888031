import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SideNav, { NavItem, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class Header extends Component {

    buttonNavButtonClicked = () => {
        console.log(document.getElementById("divNav").style.display);
        document.getElementById("divNav").style.display = 'block';
    }

    render() {
        return (
            <React.Fragment>
                { !this.props.isMobile ? 
                    // desktop
                    <div className="flexContainer">
                        <Link className="ThemeFont MenuButton btn FontSizeLevel2" to="/">home</Link> 
                        <Link className="ThemeFont MenuButton btn FontSizeLevel2" to="/gallery">gallery</Link>
                        <Link className="ThemeFont MenuButton btn FontSizeLevel2" to="/sponsors">sponsors</Link> 
                        <a href='mailto:xxxxxxxxxxx@gmail.com'>
                            <div className="ThemeFont MenuButton btn FontSizeLevel2">contact</div>
                        </a>
                    </div>
                : 
                    //mobile - use side nav
                    <div>
                        <div>
                            <i className="fa fa-bars fa-2x" onClick={this.buttonNavButtonClicked.bind(this)} style={{padding:'20px'}}></i>
                        </div>
                        <div id="divNav" style={{display:'none'}}>
                            <SideNav expanded={true} style={{backgroundColor:'black'}}
                                onToggle={(selected) => { 
                                    var currentState = document.getElementById("divNav").style.display;
                                    if(currentState=="block") {
                                        document.getElementById("divNav").style.display = 'none'
                                    }
                                    else {
                                        document.getElementById("divNav").style.display = 'block'
                                    }
                                }}
                                onSelect={(selected) => {
                                    document.getElementById("divNav").style.display = 'none';
                                    }
                                }>
                                <SideNav.Toggle/>
                                    <SideNav.Nav defaultSelected="home">
                                    <NavItem eventKey="home">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel2" to="/">home</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="gallery">
                                        <NavText>
                                            <Link className="ThemeFont MenuButton btn  FontSizeLevel2" to="/gallery">gallery</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="sponsors">
                                        <NavText>
                                        <Link className="ThemeFont MenuButton btn FontSizeLevel2" to="/sponsors">sponsors</Link> 
                                        </NavText>
                                    </NavItem>
                                    <NavItem eventKey="contact">
                                        <NavText>
                                        <a href='mailto:xxxxxxxxx@gmail.com'>
                                            <div className="ThemeFont MenuButton btn  FontSizeLevel2">contact</div>
                                        </a> 
                                        </NavText>
                                    </NavItem>
                                    
                                    <NavItem eventKey="social">
                                        <NavText style={{marginLeft:'30px'}}>
                                            {/* <a href='https://www.youtube.com/watch?v=KiaSeYsA0nI&list=PLra24N2a2c1tMInnPO0Jx0XnohMcknRxh' target='_blank' rel="noopener noreferrer"><div className="fa fa-youtube-play" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://twitter.com/SilverbackBlack' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.instagram.com/silverbackblack' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.facebook.com/silverbackblack' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a> */}


                                            <a href='https://twitter.com/khaosOXwilliams' target='_blank' rel="noopener noreferrer"><div className="fa fa-twitter" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.instagram.com/khaoswilliams/' target='_blank' rel="noopener noreferrer"><div className="fa fa-instagram" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                            <a href='https://www.facebook.com/khaosthebest' target='_blank' rel="noopener noreferrer"><div className="fa fa-facebook" style={{color:'white',fontSize:'25px',padding:'10px'}}></div></a>
                                        </NavText>
                                    </NavItem>
                                </SideNav.Nav>
                            </SideNav>
                            <div>
                        </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
export default Header;